import { createApp } from "vue";
import i18n from '@/i18n'
import App from "./App.vue";
import router from "./router";
import store from "./store";
import './assets/styles/aos.css';
import "./styles/reset.scss";
import "./styles/common.scss";
import "@/assets/styles/swiper-bundle.css";
import { GlobalVariable } from './global'
const $ = require('jquery');
window.$ = $;

const app = createApp(App)
app.config.globalProperties.$GlobalVariable = GlobalVariable;
app.use(store).use(router).use(i18n).mount("#app");
