<template>
  <div class="header">
    <div class="head_top_nav_wap">
      <div class="wap-logo">
        <img
          src="@/assets/images/home-page/icon/1.logo/normal.png"
          alt
          width="127"
          height="30"
        />
      </div>
      <span class="close"> × </span>
      <ul
        id="menu-%e6%89%8b%e6%9c%ba%e7%89%88%e4%b8%bb%e8%8f%9c%e5%8d%95"
        class="ul_head_top_nav"
      >
        <li
          id="menu-item-237"
          class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-173 current_page_item active menu-item-237 nav-item"
        >
          <a :title="$t('home')" href="/" class="nav-link">{{$t("home")}}</a>
        </li>
        <li
          id="menu-item-90"
          class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children dropdown menu-item-90 nav-item"
        >
          <a :title="$t('products')" href="#?" class="nav-link">{{$t("products")}}</a>
          <ul class="dropdown-menu mobile-nav" role="menu">
            <li
              id="menu-item-109"
              class="menu-item menu-item-type-post_type menu-item-object-page menu-item-109 nav-item"
            >
              <router-link
                :title="$t('securities')"
                to="/products/shares"
                class="dropdown-item"
                >{{$t("securities")}}</router-link
              >
            </li>
            <li
              id="menu-item-108"
              class="menu-item menu-item-type-post_type menu-item-object-page menu-item-108 nav-item"
            >
              <router-link
                :title="$t('futures')"
                to="/products/futures"
                class="dropdown-item"
                >{{$t("futures")}}</router-link
              >
            </li>
            <li
              id="menu-item-579"
              class="menu-item menu-item-type-post_type menu-item-object-page menu-item-579 nav-item"
            >
              <router-link
                :title="$t('cfds')"
                to="/products/cfds"
                class="dropdown-item"
                >{{$t("cfds")}}</router-link
              >
            </li>
          </ul>
        </li>
        <li
          id="menu-item-91"
          class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children dropdown menu-item-91 nav-item"
        >
          <a :title="$t('tools')" href="#?" class="nav-link">{{$t("tools")}}</a>
          <ul class="dropdown-menu" role="menu">
            <li
              id="menu-item-110"
              class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children dropdown menu-item-110 nav-item"
            >
              <a :title="$t('tradingPlatform')" href="#?" class="dropdown-item"
                >{{$t("tradingPlatform")}}</a
              >
              <ul class="dropdown-menu mobile-nav" role="menu">
                <li
                  id="menu-item-335"
                  class="menu-item menu-item-type-post_type menu-item-object-page menu-item-335 nav-item"
                >
                  <router-link
                    title="InTrade"
                    to="/trading-platform/intrade"
                    class="dropdown-item"
                    >InTrade</router-link
                  >
                </li>
                <!-- <li
                  id="menu-item-334"
                  class="menu-item menu-item-type-post_type menu-item-object-page menu-item-334 nav-item"
                >
                  <router-link
                    title="TradingView"
                    to="/trading-platform/tradingview"
                    class="dropdown-item"
                    >TradingView</router-link
                  >
                </li>
                <li
                  id="menu-item-333"
                  class="menu-item menu-item-type-post_type menu-item-object-page menu-item-333 nav-item"
                >
                  <router-link
                    title="MetaTrader 4"
                    to="/trading-platform/metatrader4"
                    class="dropdown-item"
                    >MetaTrader 4</router-link
                  >
                </li>
                <li
                  id="menu-item-332"
                  class="menu-item menu-item-type-post_type menu-item-object-page menu-item-332 nav-item"
                >
                  <router-link
                    title="MetaTrader 5"
                    to="/trading-platform/metatrader5"
                    class="dropdown-item"
                    >MetaTrader 5</router-link
                  >
                </li> -->
              </ul>
            </li>
            <li
              id="menu-item-115"
              class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children dropdown menu-item-115 nav-item"
            >
              <a :title="$t('tradingTools')" href="#?" class="dropdown-item"
                >{{$t("tradingTools")}}</a
              >
              <ul class="dropdown-menu mobile-nav" role="menu">
                <li
                  id="menu-item-497"
                  class="menu-item menu-item-type-post_type menu-item-object-page menu-item-497 nav-item"
                >
                  <router-link
                    :title="$t('vps')"
                    to="/trading-platform/vps"
                    class="dropdown-item"
                    >{{$t("vps")}}</router-link
                  >
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li
          id="menu-item-92"
          class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children dropdown menu-item-92 nav-item"
        >
          <a :title="$t('trading')" href="#?" class="nav-link">{{$t("trading")}}</a>
          <ul class="dropdown-menu mobile-nav" role="menu">
            <li
              id="menu-item-117"
              class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children dropdown menu-item-117 nav-item"
            >
              <a :title="$t('pricingOverview')" href="#?" class="dropdown-item"
                >{{$t("pricingOverview")}}</a
              >
              <ul class="dropdown-menu" role="menu">
                <li
                  id="menu-item-498"
                  class="menu-item menu-item-type-post_type menu-item-object-page menu-item-498 nav-item"
                >
                  <router-link
                    :title="$t('commission')"
                    to="/trading/pricing"
                    class="dropdown-item"
                    >{{$t("commission")}}</router-link
                  >
                </li>
                <li
                  id="menu-item-119"
                  class="menu-item menu-item-type-custom menu-item-object-custom menu-item-119 nav-item"
                >
                  <a :title="$t('generalFee')" href="#?" class="dropdown-item"
                    >{{$t("generalFee")}}</a
                  >
                </li>
                <li
                  id="menu-item-120"
                  class="menu-item menu-item-type-custom menu-item-object-custom menu-item-120 nav-item"
                >
                  <a :title="$t('marginRate')" href="#?" class="dropdown-item"
                    >{{$t("marginRate")}}</a
                  >
                </li>
              </ul>
            </li>
            <li
              id="menu-item-121 mobile-nav"
              class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children dropdown menu-item-121 nav-item"
            >
              <a :title="$t('tradingService')" href="#?" class="dropdown-item"
                >{{$t("tradingService")}}</a
              >
              <ul class="dropdown-menu" role="menu">
                <li
                  id="menu-item-458"
                  class="menu-item menu-item-type-post_type menu-item-object-page menu-item-458 nav-item"
                >
                  <a
                  :title="$t('funding')"
                    href="/trading/funding/"
                    class="dropdown-item"
                    >{{$t("funding")}}</a
                  >
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li
          id="menu-item-93"
          class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children dropdown menu-item-93 nav-item"
        >
          <a :title="$t('partnership')" href="#?" class="nav-link">{{$t("partnership")}}</a>
          <ul class="dropdown-menu mobile-nav" role="menu">
            <li
              id="menu-item-459"
              class="menu-item menu-item-type-post_type menu-item-object-page menu-item-459 nav-item"
            >
              <a
              :title="$t('exchangePartners')"
                href="/partnership/exchange/"
                class="dropdown-item"
                >{{$t("exchangePartners")}}</a
              >
            </li>
            <li
              id="menu-item-105"
              class="menu-item menu-item-type-custom menu-item-object-custom menu-item-105 nav-item"
            >
              <a :title="$t('introducingBroker')" href="#?" class="dropdown-item"
                >{{$t("introducingBroker")}}</a
              >
            </li>
            <li
              id="menu-item-106"
              class="menu-item menu-item-type-custom menu-item-object-custom menu-item-106 nav-item"
            >
              <a :title="$t('whiteLabelPartner')" href="#?" class="dropdown-item"
                >{{$t("whiteLabelPartner")}}</a
              >
            </li>
          </ul>
        </li>
        <li
          id="menu-item-94"
          class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children dropdown menu-item-94 nav-item"
        >
          <router-link :title="$t('aboutUs')" to="/aboutus" class="nav-link"
            >{{$t("aboutUs")}}</router-link
          >
          <ul class="dropdown-menu mobile-nav" role="menu">
            <li
              id="menu-item-457"
              class="menu-item menu-item-type-post_type menu-item-object-page menu-item-457 nav-item"
            >
              <router-link :title="$t('ourStory')" to="/aboutus" class="dropdown-item"
                >{{$t("ourStory")}}</router-link
              >
            </li>
            <li
              id="menu-item-101"
              class="menu-item menu-item-type-custom menu-item-object-custom menu-item-101 nav-item"
            >
              <a
              :title="$t('privacyPolicy')"
                target="_blank"
                href="/themes/pdf/Privacy_Policy.pdf"
                class="dropdown-item"
                >{{$t("privacyPolicy")}}</a
              >
            </li>
            <li
              id="menu-item-102"
              class="menu-item menu-item-type-custom menu-item-object-custom menu-item-102 nav-item"
            >
              <a
              :title="$t('termsOfService')"
                target="_blank"
                href="/themes/pdf/Terms_Of_Use.pdf"
                class="dropdown-item"
                >{{$t("termsOfService")}}</a
              >
            </li>
            <li
              id="menu-item-456"
              class="menu-item menu-item-type-post_type menu-item-object-page menu-item-456 nav-item"
            >
              <router-link
              :title="$t('contactUs')"
                to="/contactus"
                class="dropdown-item"
                >{{$t("contactUs")}}</router-link
              >
            </li>
          </ul>
        </li>
        <li
          id="menu-item-95"
          class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children dropdown menu-item-95 nav-item"
        >
          <a title="Language" href="#?" class="nav-link">Language</a>
          <ul class="dropdown-menu" role="menu">
            <li
              id="menu-item-537"
              class="menu-item menu-item-type-post_type menu-item-object-language_switcher current-language-menu-item menu-item-537 nav-item"
            >
              <a title="English" href="javascript:void(0)" class="dropdown-item" @click.prevent="changeLang('en')">English</a>
            </li>
            <li
              id="menu-item-603"
              class="menu-item menu-item-type-post_type menu-item-object-language_switcher menu-item-603 nav-item"
            >
              <a :title="'简体中文'" href="javascript:void(0)" class="dropdown-item" @click.prevent="changeLang('zh')">{{ '简体中文' }}</a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <div class="top">
      <div class="logo">
        <a href="/" id="biglogo_img_url"
          ><img
            src="@/assets/images/home-page/icon/1.logo/normal.png"
            alt
            width="226"
            height="54"
        /></a>
      </div>
      <div class="head_top_nav">
        <ul id="menu-main-menu" class="ul_head_top_nav">
          <li
            id="menu-item-231"
            class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-173 current_page_item active menu-item-231 nav-item"
          >
            <a :title="$t('home')" href="/" class="nav-link">{{$t('home')}}</a>
          </li>
          <li
            id="menu-item-6"
            class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children dropdown menu-item-6 nav-item"
          >
            <a :title="$t('products')" href="###" class="nav-link">{{$t('products')}}</a>
            <ul class="dropdown-menu" role="menu">
              <li
                id="menu-item-14"
                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-14 nav-item"
              >
                <router-link
                  :title="$t('securities')"
                  to="/products/shares"
                  class="dropdown-item"
                  >{{$t('securities')}}</router-link
                >
              </li>
              <li
                id="menu-item-13"
                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-13 nav-item"
              >
                <router-link
                  title="Futures"
                  to="/products/futures"
                  class="dropdown-item"
                  >{{$t('futures')}}</router-link
                >
              </li>
              <li
                id="menu-item-580"
                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-580 nav-item"
              >
                <router-link
                  :title="$t('cfds')"
                  to="/products/cfds"
                  class="dropdown-item"
                  >{{$t('cfds')}}</router-link
                >
              </li>
            </ul>
          </li>
          <li
            id="menu-item-15"
            class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children dropdown menu-item-15 nav-item"
          >
            <a :title="$t('tools')" href="#?" class="nav-link">{{$t('tools')}}</a>
            <ul class="dropdown-menu" role="menu">
              <li
                id="menu-item-51"
                class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children dropdown menu-item-51 nav-item"
              >
                <a :title="$t('tradingPlatform')" href="#?" class="dropdown-item"
                  >{{$t('tradingPlatform')}}</a
                >
                <ul class="dropdown-menu" role="menu">
                  <li
                    id="menu-item-279"
                    class="menu-item menu-item-type-post_type menu-item-object-page menu-item-279 nav-item"
                  >
                    <router-link
                      title="InTrade"
                      to="/trading-platform/intrade"
                      class="dropdown-item"
                      >InTrade</router-link
                    >
                  </li>
                  <!-- <li
                    id="menu-item-331"
                    class="menu-item menu-item-type-post_type menu-item-object-page menu-item-331 nav-item"
                  >
                    <router-link
                      title="TradingView"
                      to="/trading-platform/tradingview"
                      class="dropdown-item"
                      >TradingView</router-link
                    >
                  </li>
                  <li
                    id="menu-item-305"
                    class="menu-item menu-item-type-post_type menu-item-object-page menu-item-305 nav-item"
                  >
                    <router-link
                      title="MetaTrader 4"
                      to="/trading-platform/metatrader4"
                      class="dropdown-item"
                      >MetaTrader 4</router-link
                    >
                  </li>
                  <li
                    id="menu-item-346"
                    class="menu-item menu-item-type-post_type menu-item-object-page menu-item-346 nav-item"
                  >
                    <router-link
                      title="MetaTrader 5"
                      to="/trading-platform/metatrader5"
                      class="dropdown-item"
                      >MetaTrader 5</router-link
                    >
                  </li> -->
                </ul>
              </li>
              <li
                id="menu-item-52"
                class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children dropdown menu-item-52 nav-item"
              >
                <a :title="$t('tradingTools')" href="#?" class="dropdown-item"
                  >{{$t('tradingTools')}}</a
                >
                <ul class="dropdown-menu" role="menu">
                  <li
                    id="menu-item-499"
                    class="menu-item menu-item-type-post_type menu-item-object-page menu-item-499 nav-item"
                  >
                    <router-link
                      :title="$t('vps')"
                      to="/trading-platform/vps"
                      class="dropdown-item"
                      >{{$t('vps')}}</router-link
                    >
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li
            id="menu-item-16"
            class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children dropdown menu-item-16 nav-item"
          >
            <a :title="$t('trading')" href="###" class="nav-link">{{$t('trading')}}</a>
            <ul class="dropdown-menu" role="menu">
              <li
                id="menu-item-213"
                class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children dropdown menu-item-213 nav-item"
              >
                <a :title="$t('pricingOverview')" href="#?" class="dropdown-item"
                  >{{$t('pricingOverview')}}</a
                >
                <ul class="dropdown-menu" role="menu">
                  <li
                    id="menu-item-500"
                    class="menu-item menu-item-type-post_type menu-item-object-page menu-item-500 nav-item"
                  >
                    <router-link
                      :title="$t('commission')"
                      to="/trading/pricing"
                      class="dropdown-item"
                      >{{$t('commission')}}</router-link
                    >
                  </li>
                  <li
                    id="menu-item-215"
                    class="menu-item menu-item-type-custom menu-item-object-custom menu-item-215 nav-item"
                  >
                    <a :title="$t('generalFee')" href="#?" class="dropdown-item"
                      >{{$t('generalFee')}}</a
                    >
                  </li>
                  <li
                    id="menu-item-216"
                    class="menu-item menu-item-type-custom menu-item-object-custom menu-item-216 nav-item"
                  >
                    <a :title="$t('marginRate')" href="#?" class="dropdown-item"
                      >{{$t('marginRate')}}</a
                    >
                  </li>
                </ul>
              </li>
              <li
                id="menu-item-217"
                class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children dropdown menu-item-217 nav-item"
              >
                <a :title="$t('tradingService')" href="#?" class="dropdown-item"
                  >{{$t('tradingService')}}</a
                >
                <ul class="dropdown-menu" role="menu">
                  <li
                    id="menu-item-412"
                    class="menu-item menu-item-type-post_type menu-item-object-page menu-item-412 nav-item"
                  >
                    <a
                      :title="$t('funding')"
                      href="/trading/funding/"
                      class="dropdown-item"
                      >{{$t('funding')}}</a
                    >
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li
            id="menu-item-17"
            class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children dropdown menu-item-17 nav-item"
          >
            <a
              :title="$t('partnership')"
              href="/partnership/exchange"
              class="nav-link"
              >{{$t('partnership')}}</a
            >
            <ul class="dropdown-menu" role="menu">
              <li
                id="menu-item-413"
                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-413 nav-item"
              >
                <a
                  :title="$t('exchangePartners')"
                  href="/partnership/exchange"
                  class="dropdown-item"
                  >{{$t('exchangePartners')}}</a
                >
              </li>
              <li
                id="menu-item-59"
                class="menu-item menu-item-type-custom menu-item-object-custom menu-item-59 nav-item"
              >
                <a :title="$t('introducingBroker')" href="#?" class="dropdown-item"
                  >{{$t('introducingBroker')}}</a
                >
              </li>
              <li
                id="menu-item-60"
                class="menu-item menu-item-type-custom menu-item-object-custom menu-item-60 nav-item"
              >
                <a :title="$t('whiteLabelPartner')" href="#?" class="dropdown-item"
                  >{{$t('whiteLabelPartner')}}</a
                >
              </li>
            </ul>
          </li>
          <li
            id="menu-item-18"
            class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children dropdown menu-item-18 nav-item"
          >
            <a :title="$t('aboutUs')" href="#?" class="nav-link">{{$t('aboutUs')}}</a>
            <ul class="dropdown-menu" role="menu">
              <li
                id="menu-item-411"
                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-411 nav-item"
              >
                <router-link
                  :title="$t('ourStory')"
                  to="/aboutus"
                  class="dropdown-item"
                  >{{$t('ourStory')}}</router-link
                >
              </li>
              <li
                id="menu-item-221"
                class="menu-item menu-item-type-custom menu-item-object-custom menu-item-221 nav-item"
              >
                <a
                  :title="$t('privacyPolicy')"
                  target="_blank"
                  href="/themes/pdf/Privacy_Policy.pdf"
                  class="dropdown-item"
                  >{{$t('privacyPolicy')}}</a
                >
              </li>
              <li
                id="menu-item-222"
                class="menu-item menu-item-type-custom menu-item-object-custom menu-item-222 nav-item"
              >
                <a
                  :title="$t('termsOfService')"
                  target="_blank"
                  href="/themes/pdf/Terms_Of_Use.pdf"
                  class="dropdown-item"
                  >{{$t('termsOfService')}}</a
                >
              </li>
              <li
                id="menu-item-604"
                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-604 nav-item"
              >
                <router-link
                  :title="$t('contactUs')"
                  to="/contactus"
                  class="dropdown-item"
                  >{{$t('contactUs')}}</router-link
                >
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div class="login_area">
        <ul id="ul_login_nav" class="ul_login_nav">
          <!-- <li id="menu-item-19" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-19"><a href="https://www.ibkr.com.cn/sso/Login?partnerID=ElishBrand">Login</a></li> -->
          <!-- <li id="menu-item-20" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-20"><a href="https://ndcdyn.interactivebrokers.com/Universal/servlet/OpenAccount.IBrokerGuestLogin?partnerID=I2690393">Register</a></li> -->
          <li
            id="menu-item-22"
            class="menu-item menu-item-type-custom menu-item-object-custom menu-item-22"
          >
            <a href="#?">{{$t('helpCenter')}}</a>
          </li>
        </ul>
      </div>
      <div class="language_area">
        <div class="trp_language_switcher_shortcode">
          <div
            class="trp-language-switcher trp-language-switcher-container"
            data-no-translation
          >
            <div class="trp-ls-shortcode-current-language">
              <a
                href="#"
                class="trp-ls-shortcode-disabled-language trp-ls-disabled-language"
                :title="locale=='zh'?'简体中文':'English'"
                onclick="event.preventDefault()"
              >
                <img
                  class="trp-flag-image"
                  src="@/assets/images/flags/en_US.png"
                  width="18"
                  height="12"
                  alt="en_US"
                  :title="locale=='zh'?'简体中文':'English'"
                />
                {{locale=='zh'?'简体中文':'English'}}
              </a>
            </div>
            <div class="trp-ls-shortcode-language">
              <a
                v-if="locale=='en'"
                href="javascript:void(0);"
                class="trp-ls-shortcode-disabled-language trp-ls-disabled-language"
                title="English"
                @click.prevent="changeLang('en')"
              >
                <img
                  class="trp-flag-image"
                  src="@/assets/images/flags/en_US.png"
                  width="18"
                  height="12"
                  alt="en_US"
                  title="English"
                />
                English
              </a>
              <a href="javascript:void(0);" :title="'简体中文'"  @click.prevent="changeLang('zh')">
                <img
                  class="trp-flag-image"
                  src="@/assets/images/flags/zh_CN.png"
                  width="18"
                  height="12"
                  alt="zh_CN"
                  :title="'简体中文'"
                />
                {{ '简体中文' }}
              </a>
              <a
                v-if="locale=='zh'"
                href="javascript:void(0);"
                class="trp-ls-shortcode-disabled-language trp-ls-disabled-language"
                title="English"
                @click.prevent="changeLang('en')"
              >
                <img
                  class="trp-flag-image"
                  src="@/assets/images/flags/en_US.png"
                  width="18"
                  height="12"
                  alt="en_US"
                  title="English"
                />
                English
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="wap-menu">
        <img
          src="@/assets/images/home-page/icon/3.nav/menu_2.svg"
          alt
          width="24"
        />
      </div>
    </div>
    <div class="menu-bg"></div>
  </div>
</template>

<script setup>
import { useI18n } from "vue-i18n";
import { onMounted, onUnmounted } from "vue";
import { useStore } from 'vuex'
// const language = computed(() => store.getters.language);
// 切换语言的方法
const { locale } = useI18n();
const store = useStore()
const changeLang = (lang) => {
  locale.value = lang;
  store.commit("app/setLanguage", lang);
  location.reload()
}
onMounted(() => {
  // need to have the same with set from JS on both divs. Otherwise it can push stuff around in HTML
  var trp_ls_shortcodes = document.querySelectorAll(
    ".trp_language_switcher_shortcode .trp-language-switcher"
  );
  if (trp_ls_shortcodes.length > 0) {
    // get the last language switcher added
    var trp_el = trp_ls_shortcodes[trp_ls_shortcodes.length - 1];

    var trp_shortcode_language_item = trp_el.querySelector(
      ".trp-ls-shortcode-language"
    );
    // set width
    var trp_ls_shortcode_width = trp_shortcode_language_item.offsetWidth + 16;
    trp_shortcode_language_item.style.width = trp_ls_shortcode_width + "px";
    trp_el.querySelector(".trp-ls-shortcode-current-language").style.width =
      trp_ls_shortcode_width + "px";

    // We're putting this on display: none after we have its width.
    trp_shortcode_language_item.style.display = "none";
  }

  $(document).ready(function () {
    $(".head_top_nav_wap .ul_head_top_nav>li:not(:first-child)>a").attr(
      "href",
      "javascript:void(0)"
    );
    $(".head_top_nav .ul_head_top_nav>.menu-item-has-children").hover(
      function () {
        $("ul", this).slideDown(300);
        $(this).children("a:first").addClass("on");
        $(".menu-bg").css("display", "block").css("height", "400");
      },
      function () {
        // $('ul', this).slideUp(100);
        $(this).children("a:first").removeClass("on");
        $("ul", this).css("display", "none");
        $(".menu-bg").css("display", "none");
      }
    );
    $(".head_top_nav_wap .ul_head_top_nav>.menu-item-has-children").click(
      function () {
        // $('ul', this).slideUp(100);
        $(this).children("a:first").toggleClass("on");
        $(this).children("ul").slideToggle(300);
        $(this)
          .children(".dropdown-menu")
          .find(".dropdown-menu")
          .slideDown(300);
        $(this).siblings().find(".dropdown-menu").slideUp(300);
        $(this).siblings().children("a").removeClass("on");
        $(".menu-bg").css("display", "none");
      }
    );
    $(".mobile-nav li").click(function () {
      $(".head_top_nav_wap").css("display", "none");
    });
  });

  window.addEventListener("scroll", function () {
    let t = $("body, html").scrollTop();
    if (t > 100) {
      $(".header .top").slideDown(300);
      $(".menu-bg").css({
        position: "fixed",
        top: "0px",
        // 'border-bottom':'1px solid #888888'
      });
      $(".header .top").addClass("headerfixed");
      $(".header .head_top_nav_wap").addClass("headerfixed");
    } else {
      $(".header .top").removeClass("headerfixed");
      $(".header .head_top_nav_wap").removeClass("headerfixed");
    }
  });
});
// onUnmounted(()=>{
//   window.removeEventListener('scroll',false);
// })

</script>

<style lang="scss" scoped>
.language_area {
  padding: 0;
}
.trp-language-switcher {
  position: relative;
  display: inline-block;
  padding: 0;
  border: 0;
  margin: 2px;
  box-sizing: border-box;
}
.trp-language-switcher > div {
  box-sizing: border-box;
  padding: 3px 20px 3px 5px;
  border: 1px solid #c1c1c1;
  border-radius: 3px;
  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%);
  background-image: none;
  background-position: calc(100% - 8px) calc(1em + 0px),
    calc(100% - 3px) calc(1em + 0px);
  background-size: 5px 5px, 5px 5px;
  background-repeat: no-repeat;
  background-color: #fff;
  padding-top: 0;
  padding-bottom: 0;
  border: 0;
}

.trp-language-switcher > div > a {
  padding: 0;
}

.trp-language-switcher > div {
  box-sizing: border-box;
  padding: 3px 20px 3px 5px;
  border: 1px solid #c1c1c1;
  border-radius: 3px;
  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%);
  background-image: none;
  background-position: calc(100% - 8px) calc(1em + 0px),
    calc(100% - 3px) calc(1em + 0px);
  background-size: 5px 5px, 5px 5px;
  background-repeat: no-repeat;
  background-color: #fff;
  padding-top: 0;
  padding-bottom: 0;
  border: 0;
}

.trp-language-switcher > div > a {
  padding: 0;
}

.trp-language-switcher-container
  > div.trp-ls-shortcode-current-language:first-child::after {
  content: "";
  display: inline-block;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  border: 5px solid transparent;
  border-top-color: #222;
  position: absolute;
  top: 25px;
  right: 8px;
  width: 0;
  height: 0;
}
.trp-language-switcher-container
  > div.trp-ls-shortcode-current-language:first-child::after {
  content: "";
  display: inline-block;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  border: 5px solid transparent;
  border-top-color: #222;
  position: absolute;
  top: 25px;
  right: 8px;
  width: 0;
  height: 0;
}
.trp-language-switcher:focus .trp-ls-shortcode-language,
.trp-language-switcher:hover .trp-ls-shortcode-language {
  visibility: visible;
  max-height: 250px;
  height: auto;
  overflow-y: auto;
  position: absolute;
  left: 0;
  top: 0;
  display: inline-block !important;
  min-height: auto;
}
.trp-language-switcher > div > a {
  display: block;
  padding: 2px;
  border-radius: 3px;
  color: rgb(7, 105, 173);
}
.language_area a:hover {
  color: #d41e44;
  transition: all 600ms;
}

.trp-language-switcher > div > a.trp-ls-shortcode-disabled-language:hover {
  background: none;
}
.trp-language-switcher > div > a.trp-ls-shortcode-disabled-language {
  cursor: default;
}
.trp-language-switcher:focus .trp-ls-shortcode-language,
.trp-language-switcher:hover .trp-ls-shortcode-language {
  visibility: visible;
  max-height: 250px;
  height: auto;
  overflow-y: auto;
  position: absolute;
  left: 0;
  top: 0;
  display: inline-block !important;
  min-height: auto;
}
</style>
