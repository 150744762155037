<template>
  <!-- <div class="footer-top">
      <div class="container">
          <div class="left">
              <div class="title">Start Your Global Investment Journey</div>
              <div class="des">
                  <span>Register</span>
                  <img src="@/assets/images/home-page/icon/0.other/arrow-right.svg" alt>
                  <span>Verify</span>
                  <img src="@/assets/images/home-page/icon/0.other/arrow-right.svg" alt>
                  <span>Deposit</span>
                  <img src="@/assets/images/home-page/icon/0.other/arrow-right.svg" alt>
                  <span>Trading</span>
              </div>
          </div>
          <div class="right">
              <p class="alltip">Start Your Global Investment Journey</p>
              <p class="register"><a class="register" href="https://ndcdyn.interactivebrokers.com/Universal/servlet/OpenAccount.IBrokerGuestLogin?partnerID=I2690393">Register</a></p>
          </div>
      </div>
  </div> -->
  <div class="footer">
      <div class="container">
          <div class="footer-logo">
              <img src="@/assets/images/home-page/icon/1.logo/whitte.png" alt width="196" height="46">
          </div>
          <div class="footer-nav">
              <ul id="menu-footer-menu" class="ul_foot_bottom_nav ">
                  <li id="menu-item-23" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children dropdown menu-item-23 nav-item"><a :title="$t('products')" href="#?" class="nav-link">{{$t('products')}}</a>
                      <ul class="dropdown-menu" role="menu">
                          <li id="menu-item-26" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-26 nav-item"><router-link :title="$t('securities')" to="/products/shares" class="dropdown-item">{{$t('securities')}}</router-link></li>
                          <li id="menu-item-25" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-25 nav-item"><router-link :title="$t('futures')" to="/products/futures" class="dropdown-item">{{$t('futures')}}</router-link></li>
                          <li id="menu-item-581" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-581 nav-item"><router-link :title="$t('cfds')" to="/products/cfds" class="dropdown-item">{{$t('cfds')}}</router-link></li>
                      </ul>
                  </li>
                  <li id="menu-item-27" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children dropdown menu-item-27 nav-item"><a :title="$t('tools')" href="#?" class="nav-link">{{$t('tools')}}</a>
                      <ul class="dropdown-menu" role="menu">
                          <li id="menu-item-28" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children dropdown menu-item-28 nav-item"><a :title="$t('tradingPlatform')" href="#?" class="dropdown-item">{{$t('tradingPlatform')}}</a>
                              <ul class="dropdown-menu" role="menu">
                                  <li id="menu-item-278" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-278 nav-item"><router-link title="InTrade" to="/trading-platform/intrade" class="dropdown-item">InTrade</router-link></li>
                                  <!-- <li id="menu-item-338" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-338 nav-item"><router-link title="TradingView" to="/trading-platform/tradingview" class="dropdown-item">TradingView</router-link></li>
                                  <li id="menu-item-337" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-337 nav-item"><router-link title="MetaTrader 4" to="/trading-platform/metatrader4" class="dropdown-item">MetaTrader 4</router-link></li>
                                  <li id="menu-item-336" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-336 nav-item"><router-link title="MetaTrader 5" to="/trading-platform/metatrader5" class="dropdown-item">MetaTrader 5</router-link></li> -->
                              </ul>
                          </li>
                          <li id="menu-item-29" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children dropdown menu-item-29 nav-item"><a :title="$t('tradingTools')" href="#?" class="dropdown-item">{{$t('tradingTools')}}</a>
                              <ul class="dropdown-menu" role="menu">
                                  <li id="menu-item-501" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-501 nav-item"><router-link :title="$t('vps')" to="/trading-tools/vps" class="dropdown-item">{{$t('vps')}}</router-link></li>
                              </ul>
                          </li>
                      </ul>
                  </li>
                  <li id="menu-item-35" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children dropdown menu-item-35 nav-item"><a :title="$t('trading')" href="#?" class="nav-link">{{$t('trading')}}</a>
                      <ul class="dropdown-menu" role="menu">
                          <li id="menu-item-36" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children dropdown menu-item-36 nav-item"><a :title="$t('pricingOverview')" href="#?" class="dropdown-item">{{$t('pricingOverview')}}</a>
                              <ul class="dropdown-menu" role="menu">
                                  <li id="menu-item-502" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-502 nav-item"><router-link :title="$t('commission')" to="/trading/pricing/" class="dropdown-item">{{$t('commission')}}</router-link></li>
                                  <li id="menu-item-38" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-38 nav-item"><a :title="$t('tradingTools')" href="#?" class="dropdown-item">{{$t('tradingTools')}}</a></li>
                                  <li id="menu-item-39" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-39 nav-item"><a :title="$t('tradingTools')" href="#?" class="dropdown-item">{{$t('tradingTools')}}</a></li>
                              </ul>
                          </li>
                          <li id="menu-item-40" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children dropdown menu-item-40 nav-item"><a :title="$t('tradingService')" href="#?" class="dropdown-item">{{$t('tradingService')}}</a>
                              <ul class="dropdown-menu" role="menu">
                                  <li id="menu-item-452" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-452 nav-item"><a :title="$t('funding')" href="/trading/funding/" class="dropdown-item">{{$t('funding')}}</a></li>
                              </ul>
                          </li>
                      </ul>
                  </li>
                  <li id="menu-item-41" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children dropdown menu-item-41 nav-item"><a :title="$t('partnership')" href="#?" class="nav-link">{{$t('partnership')}}</a>
                      <ul class="dropdown-menu" role="menu">
                          <li id="menu-item-453" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-453 nav-item"><a :title="$t('exchangePartners')" href="/partnership/exchange/" class="dropdown-item">{{$t('exchangePartners')}}</a></li>
                          <li id="menu-item-43" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-43 nav-item"><a :title="$t('introducingBroker')" href="#?" class="dropdown-item">{{$t('introducingBroker')}}</a></li>
                          <li id="menu-item-44" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-44 nav-item"><a :title="$t('whiteLabelPartner')" href="#?" class="dropdown-item">{{$t('whiteLabelPartner')}}</a></li>
                      </ul>
                  </li>
                  <li id="menu-item-45" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children dropdown menu-item-45 nav-item"><router-link :title="$t('aboutUs')" to="/aboutus" class="nav-link">{{$t('aboutUs')}}</router-link>
                      <ul class="dropdown-menu" role="menu">
                          <li id="menu-item-451" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-451 nav-item"><router-link :title="$t('ourStory')" to="/aboutus" class="dropdown-item">{{$t('ourStory')}}</router-link></li>
                          <li id="menu-item-48" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-48 nav-item"><a :title="$t('privacyPolicy')" target="_blank" href="/themes/pdf/Privacy_Policy.pdf" class="dropdown-item">{{$t('privacyPolicy')}}</a></li>
                          <li id="menu-item-49" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-49 nav-item"><a :title="$t('termsOfService')" target="_blank" href="/themes/pdf/Terms_Of_Use.pdf" class="dropdown-item">{{$t('termsOfService')}}</a></li>
                          <li id="menu-item-450" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-450 nav-item"><router-link :title="$t('contactUs')" to="/contactus/" class="dropdown-item">{{$t('contactUs')}}</router-link></li>
                      </ul>
                  </li>
              </ul>
          </div>
          <div class="foot-con">
              <ul class="con_ul">
                 <!--  <li>
                      <div class="title">
                          {{$t('dooEntitiesNotice')}}
                      </div>
                      <div class="con">
                          <div class="row">
                              <div class="left">
                                  <span class="con_title">Elish & Elish Inc</span>
                                  <div class="con_des">
                                      <p>It is a company with the registered office address at 5049 Edwards Ranch Road, Suite 400, Fort Worth, TX 76109, United States.</p>
                                      <p>Elish & Elish Inc is a broker-dealer, authorized and regulated by the United States Securities and Exchange Commission (US SEC) and Financial Industry Regulatory Authority (US FINRA) with the regulation number SEC:
                                          8-41551 and CRD: 24409 respectively.</p>
                                      <p>Peter Elish Investments Securities is an authorised trading name by Elish & Elish Inc.</p>
                                  </div>
                              </div>
                              <div class="right">
                                  <span class="con_title">Doo Clearing Limited</span>
                                  <div class="con_des">
                                      <p>It is a company with the registered office address at Berkeley Suite 35 Berkeley Square, Mayfair, London, England, W1J 5BF, and the company registration number is 10684079.</p>
                                      <p>Doo Clearing Limited is a futures and forex broker and liquidity provider, authorized and regulated by the United Kingdom Financial Conduct Authority (UK FCA), and the regulatory number is 833414.</p>
                                  </div>
                              </div>
                              <div class="clear"></div>
                          </div>
                          <div class="row">
                              <div class="left">
                                  <span class="con_title">Lay Ray Australia Limited</span>
                                  <div class="con_des">
                                      <p>It is a company with the registered office address at Level 4, 28-34 O"Connell Street, Sydney NSW 2000, and the Australian Company Number is 100 139 20.</p>
                                      <p>Lay Ray Australia Limited is an Australian financial services licensee, authorized and regulated by the Australian Securities & Investments Commission (ASIC), and the regulatory number is 222650.</p>
                                  </div>
                              </div>
                              <div class="right">
                                  <span class="con_title">Doo Exchange AU Pty Ltd</span>
                                  <div class="con_des">
                                      <p>It is a company with the registered office address at Suite 607 Level 6, 109 Pitt Street, Sydney NSW 2000, and the Australian Company Number is 661 717 237.</p>
                                      <p>Doo Exchange AU Pty Ltd is a Currency Exchange and Remittance service provider, authorized and regulated by the Australian Transaction Reports and Analysis Centre (AUSTRAC), and the authorizations number are IND100812107-001
                                          and DCE100812107-001 respectively.</p>
                                  </div>
                              </div>
                              <div class="clear"></div>
                          </div>
                          <div class="row">
                              <div class="left">
                                  <span class="con_title">Lay Ray HK Limited</span>
                                  <div class="con_des">
                                      <p>It is a company with the registered office address at Unit A-1, 25/F, Tower B, Billion Centre, No.1 Wang Kwong Road, Kowloon Bay, Hong Kong, and the company registration number is 2099737.</p>
                                      <p>Lay Ray HK Limited is licensed for Type 4 (advising on securities) and Type 9 (asset management) activities, regulated by the Securities and Futures Commission of Hong Kong, and the Central Entity number is
                                          BSM562.</p>
                                  </div>
                              </div>
                              <div class="right">
                                  <span class="con_title">Doo Wealth Management HK Limited</span>
                                  <div class="con_des">
                                      <p>It is a company with the registered office address at Unit E, 30/F, Tower A, Billion Centre, No.1 Wang Kwong Road, Kowloon Bay, Kowloon, Hong Kong, and the company registration number is 2507030.</p>
                                      <p>Doo Wealth Management HK Limited is a licensed insurance broker company, authorized and regulated by the Hong Kong Insurance Authority (Insurance Broker License), and the regulatory number is FB1823.</p>
                                  </div>
                              </div>
                              <div class="clear"></div>
                          </div>
                          <div class="row">
                              <div class="left">
                                  <span class="con_title">Doo Holding Group Limited</span>
                                  <div class="con_des">
                                      <p>It is a company with the registered office address at Unit A, 25/F, Tower B, Billion Centre, No.1 Wang Kwong Road, Kowloon Bay, Hong Kong, and the company registration number is 2169884.</p>
                                      <p>Doo Holding Group Limited is a licensed money service operator, authorized and regulated by the Hong Kong Customs and Excise Department (Money Service Operator License), and regulatory number is 18-06-02444.</p>
                                  </div>
                              </div>
                              <div class="right">
                                  <span class="con_title">Doo Properties HK Limited</span>
                                  <div class="con_des">
                                      <p>It is a company with the registered office address at Unit E, 30 Floor, Tower A, Billion Centre, No.1 Wang Kwong Road, Kowloon Bay, Kowloon, Hong Kong, and the company registration number is 3106018.</p>
                                      <p>Doo Properties HK Limited is a real estate agency, regulated by the Hong Kong Estate Agents Authority, and the license number is C-094694.</p>
                                  </div>
                              </div>
                              <div class="clear"></div>
                          </div>
                          <div class="row">
                              <div class="left">
                                  <span class="con_title">Doo Consulting Limited</span>
                                  <div class="con_des">
                                      <p>It is a company with the registered office address at Unit A, 25/F, Tower B, Billion Centre, No.1 Wang Kwong Road, Kowloon Bay, Hong Kong, and the company registration number is 2601567.</p>
                                      <p>Doo Consulting Limited is a licensed company service provider, authorized and regulated by the Hong Kong Companies Registry (HK CR), and the regulatory number is TC006709.</p>
                                  </div>
                              </div>
                              <div class="right">
                              </div>
                              <div class="clear"></div>
                          </div>
                      </div>
                  </li> -->
                  <li>
                      <div class="title">
                          {{$t('RiskDisclosure')}}
                      </div>
                      <div class="con">
                          <div class="con_des">
                              <p>{{$t('RiskDisclosureContent1')}}</p>
                          </div>
                      </div>
                  </li>
                  <!-- <li>
                      <div class="title">
                          {{$t('BrandandEntityNotice')}}
                      </div>
                      <div class="con">
                          <div class="con_des">
                              <p>{{$t('BrandandEntityNoticeContent1')}}</p>
                              <p>{{$t('BrandandEntityNoticeContent2')}}</p>
                              <p>{{$t('BrandandEntityNoticeContent3')}}
                                  <div class="height8"></div>
                                  {{$t('BrandandEntityNoticeContent4')}}
                                  <div class="height8"></div>
                                  {{$t('BrandandEntityNoticeContent5')}}
                                  <div class="height8"></div>
                                  {{$t('BrandandEntityNoticeContent6')}}
                              </p>
                          </div>
                      </div>
                  </li>
                  <li>
                      <div class="title">
                          {{$t('WebsiteTerms')}}
                      </div>
                      <div class="con">
                          <div class="con_des">
                              <p>{{$t('WebsiteTermsContent1')}}</p>
                              <p>{{$t('WebsiteTermsContent2')}}</p>
                              <p>{{$t('WebsiteTermsContent3')}}</p>
                              <p>{{$t('WebsiteTermsContent4')}}</p>
                              <p>{{$t('WebsiteTermsContent5')}}</p>
                              <p>{{$t('WebsiteTermsContent6')}}</p>
                              <p>{{$t('WebsiteTermsContent7')}}</p>
                              <p>{{$t('WebsiteTermsContent8')}}</p>
                              <p>{{$t('WebsiteTermsContent9')}}</p>
                          </div>
                      </div>
                  </li> -->
              </ul>
          </div>
          <!-- <div class="footer-copyright">
              <div class="left">{{$t("asset")}}</div>
          </div> -->
      </div>
      <!-- <div class="footer-end">
          <div class="container">
              {{$t('company')}}<span style="width: 8px;display: inline-block;"></span>© 2021 Lay Ray . All Rights Reserved .
          </div>
      </div> -->
  </div>
</template>

<script setup>
import { onMounted } from 'vue';
onMounted(()=>{
  $(document).ready(function() {
      var width = $(window).width();
      if (width < 1024) {
          $('.foot-con .title').click(function() {
              $(this).parent().siblings().find('.con').slideUp(600);
              $(this).parent().siblings().find('.title').removeClass('act').addClass('clearcon');
              $(this).removeClass('clearcon').toggleClass('act').next().slideToggle(600); //
              $(this).parents('.container').find('.ul_foot_bottom_nav>li.on').removeClass('on').next('.dropdown-menu').slideUp(600);
              $(this).parents('.container').find('.ul_foot_bottom_nav>li').find('.dropdown-menu').slideUp(600);
          });
      } else {
          $('.foot-con .title').click(function() {
              $(this).parent().siblings().find('.con').slideUp(600);
              $(this).parent().siblings().find('.title').removeClass('act').addClass('clearcon');;
              $(this).removeClass('clearcon').toggleClass('act').next().slideToggle(600); //
          });
      }
      $(".ul_foot_bottom_nav>li>a").attr('href', 'javascript:void(0)');
      if (width < 1025) {
          $(".ul_foot_bottom_nav>li>a").click(function() {
              $(this).next('.dropdown-menu').slideToggle(300).parent().toggleClass('on');
              $(this).next('.dropdown-menu').find('.dropdown-menu').slideDown(300);
              $(this).parent().siblings().removeClass('on').removeClass('current-menu-item').removeClass('current_page_item');
              $(this).parent().siblings().find('.dropdown-menu').slideUp(600);
          });
          $('.wap-menu').click(function() {
              $('.head_top_nav_wap').css('display', 'flex').animate(300);
          });
          $('span.close').click(function() {
              $('.head_top_nav_wap').css('display', 'none').animate(300);
          });
      }
  });
})
</script>

<style lang="scss" scoped>
</style>