import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import(/* webpackChunkName: "home" */ "../views/Home.vue"),
  },
  {
    path: "/products",
    name: "products",
    component: () =>
      import(
        /* webpackChunkName: "products" */ '@/views/products/index.vue'
      ),
    children: [
      {
        path: 'shares',
        name: 'shares',
        component: () =>
          import(
            /* webpackChunkName: "shares" */ '@/views/products/shares.vue'
          )
      },
      {
        path: 'futures',
        name: 'futures',
        component: () =>
          import(
            /* webpackChunkName: "futures" */ '@/views/products/futures.vue'
          )
      },
      {
        path: 'cfds',
        name: 'cfds',
        component: () =>
          import(
            /* webpackChunkName: "cfds" */ '@/views/products/cfds.vue'
          )
      },
    ]
  },
  {
    path: "/trading-platform",
    name: "trading-platform",
    component: () =>
      import(
        /* webpackChunkName: "tradingPlatform" */ '@/views/tradingPlatform/index.vue'
      ),
    children: [
      {
        path: 'intrade',
        name: 'intrade',
        component: () =>
          import(
            /* webpackChunkName: "intrade" */ '@/views/tradingPlatform/intrade.vue'
          )
      },
      {
        path: 'tradingview',
        name: 'tradingview',
        component: () =>
          import(
            /* webpackChunkName: "tradingview" */ '@/views/tradingPlatform/tradingview.vue'
          )
      },
      {
        path: 'metatrader4',
        name: 'metatrader4',
        component: () =>
          import(
            /* webpackChunkName: "metatrader4" */ '@/views/tradingPlatform/metatrader4.vue'
          )
      },
      {
        path: 'metatrader5',
        name: 'metatrader5',
        component: () =>
          import(
            /* webpackChunkName: "metatrader5" */ '@/views/tradingPlatform/metatrader5.vue'
          )
      },
      {
        path: 'vps',
        name: 'vps',
        component: () =>
          import(
            /* webpackChunkName: "vps" */ '@/views/tradingPlatform/vps.vue'
          )
      },
    ]
  },
  {
    path: "/trading",
    name: "trading",
    component: () =>
      import(
        /* webpackChunkName: "trading" */ '@/views/trading/index.vue'
      ),
    children: [
      {
        path: 'pricing',
        name: 'pricing',
        component: () =>
          import(
            /* webpackChunkName: "pricing" */ '@/views/trading/pricing.vue'
          )
      },
      {
        path: 'funding',
        name: 'funding',
        component: () =>
          import(
            /* webpackChunkName: "funding" */ '@/views/trading/funding.vue'
          )
      },
    ]
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/aboutus.vue"),
  },
  {
    path: "/trading-platform",
    name: "trading-platform",
    component: () =>
      import(
        /* webpackChunkName: "tradingPlatform" */ '@/views/tradingPlatform/index.vue'
      ),
    children: [
      {
        path: 'intrade',
        name: 'intrade',
        component: () =>
          import(
            /* webpackChunkName: "intrade" */ '@/views/tradingPlatform/intrade.vue'
          )
      },
      {
        path: 'tradingview',
        name: 'tradingview',
        component: () =>
          import(
            /* webpackChunkName: "tradingview" */ '@/views/tradingPlatform/tradingview.vue'
          )
      },
      {
        path: 'metatrader4',
        name: 'metatrader4',
        component: () =>
          import(
            /* webpackChunkName: "metatrader4" */ '@/views/tradingPlatform/metatrader4.vue'
          )
      },
      {
        path: 'metatrader5',
        name: 'metatrader5',
        component: () =>
          import(
            /* webpackChunkName: "metatrader5" */ '@/views/tradingPlatform/metatrader5.vue'
          )
      },
      {
        path: 'vps',
        name: 'vps',
        component: () =>
          import(
            /* webpackChunkName: "vps" */ '@/views/tradingPlatform/vps.vue'
          )
      },
    ]
  },
  {
    path: "/partnership",
    name: "partnership",
    component: () =>
      import(
        /* webpackChunkName: "partnership" */ '@/views/partnership/index.vue'
      ),
    children: [
      {
        path: 'exchange',
        name: 'exchange',
        component: () =>
          import(
            /* webpackChunkName: "exchange" */ '@/views/partnership/exchange.vue'
          )
      }
    ]
  },
  {
    path: "/aboutus",
    name: "aboutus",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/aboutus.vue"),
  },
  {
    path: "/contactus",
    name: "contactus",
    component: () =>
      import(/* webpackChunkName: "contactus" */ "@/views/contactus.vue"),
  },
];
const  scrollBehavior = (to, from, savedPosition) => {
    if (savedPosition && to.meta.keepAlive) {
      return savedPosition;
    }
    return { left: 0, top:0 };
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior,
});

export default router;
